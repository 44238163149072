import { Box, Grid, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import InView from "react-intersection-observer";
import YouTube from "react-youtube";
import ContactComponent from "../../components/ContactComponent";
import HeroComponent from "../../components/HeroComponent";
import Layout from "../../components/Layout";
import useHover from "../../hooks/useHover";

const IMAGES = [
  {
    src: "/img/ae-meetings-one.jpg",
    thumbnail: "/img/ae-meetings-one.jpg",
    alt: "aevias meetings example one",
  },
  {
    src: "/img/ae-meetings-two.jpg",
    thumbnail: "/img/ae-meetings-two.jpg",
    alt: "aevias meetings example two",
  },
  {
    src: "/img/ae-meetings-three.jpg",
    thumbnail: "/img/ae-meetings-three.jpg",
    alt: "aevias meetings example thre",
  },
  {
    src: "/img/ae-meetings-four.jpg",
    thumbnail: "/img/ae-meetings-four.jpg",
    alt: "aevias meetings example four",
  },
  {
    src: "/img/ae-meetings-five.png",
    thumbnail: "/img/ae-meetings-five.png",
    alt: "aevias meetings example five",
  },
  {
    src: "/img/ae-meetings-six.jpg",
    thumbnail: "/img/ae-meetings-six.jpg",
    alt: "aevias meetings example six",
  },
];

const MeetingAndConferencesPage = ({ location }) => {
  const [heroTextRef, isHeroTextHovered] = useHover();
  const scrollHere = useRef(null);

  useEffect(() => {
    if (isHeroTextHovered) {
      console.log("hovered");
    }
  }, [isHeroTextHovered]);

  const renderSolutions = () => {
    const solutions = [
      {
        icon: "/img/meeting/ae-budget.svg",
        title: "Post-event evaluation and reporting",
      },

      {
        icon: "/img/meeting/ae-technical.svg",
        title: "Translation and accessible services",
      },
      {
        icon: "/img/meeting/ae-housing.svg",
        title: "Housing, venue management and registration",
      },

      {
        icon: "/img/meeting/ae-strategy-design.svg",
        title: "Strategic design and delivery",
      },
      {
        icon: "/img/meeting/ae-content.svg",
        title: "Content strategy and creation",
      },
      {
        icon: "/img/meeting/ae-speaker.svg",
        title: "Speaker and entertainment management",
      },

      {
        icon: "/img/meeting/ae-budget.svg",
        title: "Budget development and management",
      },
      {
        icon: "/img/meeting/ae-speaker.svg",
        title: "Staging and production",
      },
      {
        icon: "/img/meeting/ae-content.svg",
        title: "Sponsorship and exhibitor management",
      },

      {
        icon: "/img/meeting/ae-technical.svg",
        title: "Technical production",
      },
      {
        icon: "/img/meeting/ae-measurement.svg",
        title: "Measurement for success",
      },

      {
        icon: "/img/meeting/ae-measurement.svg",
        title: "Event staffing",
      },
      {
        icon: "",
        title: "And so much more",
      },
    ];

    return solutions.map((solution, index) => (
      <Grid
        key={index}
        gridTemplateColumns={{
          base: "24px 1fr",
          md: "32px 1fr",
        }}
        columnGap="24px"
        alignItems="center"
        marginBottom="24px"
      >
        {solution.icon ? (
          <img src={solution.icon} alt={solution.title} />
        ) : (
          <Box width="32px" height="32px" />
        )}
        <Text
          fontWeight={500}
          fontSize={{ base: "20px", md: "24px" }}
          lineHeight={{ base: "28px", md: "32px" }}
          color="secondary.500"
        >
          {solution.title}
        </Text>
      </Grid>
    ));
  };

  return (
    <Layout location={location}>
      <Box
        as="section"
        position="relative"
        flex="1"
        height="100%"
        background="#333333"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        minH="85vh"
        maxH="672px"
      >
        <Box
          position="relative"
          zIndex="5"
          px={{ base: 0, md: "6rem" }}
          textAlign="center"
        >
          <Box
            display="flex"
            color="#FFFFFF"
            fontSize={{ base: "64px", md: "96px", lg: "120px" }}
            lineHeight={{ base: "80px", md: "104px", lg: "128px" }}
            marginBottom={{ base: 6, md: 6 }}
          >
            <motion.span
              ref={heroTextRef}
              onClick={() => {
                scrollHere.current.scrollIntoView({ behavior: "smooth" });
                window.scrollTo({
                  top: 600,
                  behavior: "smooth",
                });
              }}
              animate={{
                opacity: 1,
                transition: { duration: 1.2, delay: 0 },
              }}
              initial={{ opacity: 0 }}
              whileHover={{
                y: -12,
                transition: {
                  ease: "easeInOut",
                  duration: 0.6,
                  repeat: Infinity,
                  repeatType: "mirror",
                },
              }}
              style={{
                color: "#C3F9E0",
                width: "100%",
                position: "relative",
                fontFamily: "Antonio",
                cursor: "pointer",
              }}
            >
              Meetings & Conferences
            </motion.span>
          </Box>
        </Box>
        <Box
          position="relative"
          zIndex="5"
          display="flex"
          justifyContent="center"
          textAlign="center"
          fontSize={{ base: "18px", md: "24px", lg: "32px" }}
          lineHeight={{ base: "26px", md: "32px", lg: "40px" }}
          px={{ base: "24px", md: 0 }}
        >
          <motion.span
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 1.2, delay: 0.6, ease: "easeInOut" },
            }}
            initial={{ opacity: 0, y: -80 }}
            style={{
              position: "relative",
              fontFamily: "Montserrat",
              color: "#ffffff",
              fontWeight: 600,
            }}
          >
            Bring your business ideas and key
            <br />
            messages to life through
            <br />
            engaging experiences.
          </motion.span>
        </Box>
        <Box>
          <Box
            position="absolute"
            zIndex="4"
            width="100%"
            height="100%"
            top="0"
            left="0"
            right="0"
            bottom="0"
            opacity="0.5"
            backgroundColor={isHeroTextHovered ? "secondary.700" : "#000000"}
            backgroundBlendMode={isHeroTextHovered ? "multiply" : "initial"}
            transition="2s all ease-in-out"
          />
          <HeroComponent>
            <Box
              height="100%"
              backgroundImage="url('/img/ae-meeting-hero.jpg')"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
            />
          </HeroComponent>
        </Box>
      </Box>

      <Box ref={scrollHere} as="section">
        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <Box
              as="section"
              my={{ base: "40px", md: "8rem" }}
              display="flex"
              alignItems={{ base: "flex-start", md: "center" }}
              pl={{ base: "24px", md: "6rem" }}
            >
              <Box ref={ref}>
                {inView && (
                  <Grid
                    gridTemplateColumns={{ base: "none", md: "0.75fr 1fr" }}
                    gridTemplateRows={{ base: "auto auto", md: "none" }}
                    gridColumnGap={{ base: 0, md: "160px" }}
                    gridRowGap={{ base: "40px", md: 0 }}
                    alignItems="center"
                  >
                    <Box>
                      <Text as="h2" className="ae-title-two" mb="6">
                        You’ve got big ideas so let us bring them to life.
                      </Text>
                      <Text as="p" className="ae-desc-one">
                        We’ll work with you to identify your key business
                        objectives and deliver a strategic meeting, conference
                        or business event to meet your goals. Whether it’s sales
                        meetings, town halls, user conferencers, product
                        launches, trade shows, or even road shows, we’ve got you
                        covered, wherever you need to go.
                      </Text>
                    </Box>
                    <Box
                      position="relative"
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src="/img/ae-meetings-section.jpg"
                        alt="Aevias meetings and conferences"
                      />
                      <Box
                        zIndex="-1"
                        background="primary.500"
                        width="calc(100% + 40px)"
                        height="100%"
                        position="absolute"
                        right="0"
                        bottom="-40px"
                      />
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}
        </InView>
      </Box>

      <Box as="section">
        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <Box
              as="section"
              mt="4rem"
              mb={{ base: 12, md: "8rem" }}
              display="flex"
              alignItems="center"
            >
              <Box ref={ref} className="wrapper-xl">
                <Text
                  as="h2"
                  color="secondary.500"
                  fontSize={{ base: "56px", md: "64px", lg: "80px" }}
                  lineHeight={{ base: "64px", md: "72px", lg: "88px" }}
                  fontWeight="300"
                  letterSpacing="-5px"
                  mb={{ base: 6, md: 8, lg: 12 }}
                >
                  How Aevias Can Help
                </Text>
                {inView && (
                  <Box display="flex">
                    <Box
                      display={{ base: "flex", md: "grid" }}
                      flexDir="column"
                      gridTemplateColumns="1fr 1fr"
                      gridColumnGap="160px"
                    >
                      {renderSolutions()}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </InView>
      </Box>

      <Box
        display={{ base: "flex", md: "none" }}
        flexDir="column"
        overflow="hidden"
        marginBottom="6"
      >
        <Box
          display={{ base: "flex", md: "grid" }}
          gridTemplateColumns="auto auto auto"
          overflowX="scroll"
        >
          <img src={IMAGES[0].src} alt={IMAGES[0].alt} />
          <img src={IMAGES[1].src} alt={IMAGES[1].alt} />
          <img src={IMAGES[2].src} alt={IMAGES[2].alt} />
          <img src={IMAGES[3].src} alt={IMAGES[3].alt} />
          <img src={IMAGES[4].src} alt={IMAGES[4].alt} />
          <img src={IMAGES[5].src} alt={IMAGES[5].alt} />
        </Box>
      </Box>

      <Box display={{ base: "none", md: "flex" }} flexDir="column">
        <Box display="grid" gridTemplateColumns="auto auto auto">
          <img className="ae-image" src={IMAGES[0].src} alt={IMAGES[0].alt} />
          <img className="ae-image" src={IMAGES[1].src} alt={IMAGES[1].alt} />
          <img className="ae-image" src={IMAGES[2].src} alt={IMAGES[2].alt} />
        </Box>
        <Box display="grid" gridTemplateColumns="auto auto auto">
          <img className="ae-image" src={IMAGES[3].src} alt={IMAGES[3].alt} />
          <img className="ae-image" src={IMAGES[4].src} alt={IMAGES[4].alt} />
          <img className="ae-image" src={IMAGES[5].src} alt={IMAGES[5].alt} />
        </Box>
      </Box>

      <Box
        mt={{ base: "160px", md: "180px", lg: "240px" }}
        backgroundColor="primary.500"
        as="section"
        position="relative"
        py={{ base: "60px", md: "80px", lg: "120px" }}
        pb={{ base: "1rem", md: "4rem", lg: "4rem" }}
      >
        <Box className="wrapper-xl">
          <Text
            top={{ base: "-5.5rem", md: "-12rem", lg: "-9rem" }}
            position="absolute"
            whiteSpace="pre-wrap"
            as="h2"
            color="secondary.500"
            letterSpacing="-5px"
            fontSize={{ base: "48px", md: "64px", lg: "80px" }}
            lineHeight={{ base: "56px", md: "72px", lg: "88px" }}
          >
            Here's a taste of what
            <br />
            we can do
          </Text>
          <Box>
            <YouTube
              containerClassName="youtubeContainer"
              videoId="VnBu5taUp2Y"
              opts={{
                height: "100%",
                width: "100%",
                playerVars: {
                  autoplay: 0,
                },
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <ContactComponent />
      </Box>
    </Layout>
  );
};

export default MeetingAndConferencesPage;
